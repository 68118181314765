import React from "react";
import { storage } from "./firebase";
import { ref, getDownloadURL } from "firebase/storage";

const Help = (props) => { 
  
  const openFullscreenPopup = (url) => {
  
    const width = window.screen.availWidth;
    const height = window.screen.availHeight;

    const features = `width=${width},height=${height},left=0,top=0,resizable=yes,scrollbars=yes,status=yes`;

    const popupWindow = window.open(url, '_blank', features);

    if (popupWindow) popupWindow.focus();
  }

  const openHelp = () => {
    if (props.help !== "") {
      const fileRef = ref(storage, `guides/${props.help}`);
      getDownloadURL(fileRef).then((url) => {
        openFullscreenPopup(url)
      }).catch((error) => {
        alert("Error loading PDF: " + error.message);
      });
    }
  };

  return (
    <div>
      
      <button 
        type="button" 
        className="btn btn-link"
        onClick={openHelp} 
        disabled={props.help === ""}
        style={{ 
          outline: 'none', 
          boxShadow: 'none',
          border: 'none', 
          textDecoration: 'none' ,
        }}
      >
        {props.btn_txt}
      </button>

    </div>
  );
};

export default Help;
