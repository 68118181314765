import React, { useState,useEffect } from "react";
import { Modal,Table} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import ReactLoading from 'react-loading';
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL; 


const PrivateMeetingsList=(props)=>{ 

    const [lines,setLines]=useState([]); 
    const [show,setShow]=useState(false); 
    const [fire,setFire]=useState(false);  

    
    const fetchPrivateLessonsDetails = async () => {
        try {
            setFire(true); 
    
            const response = await axios.get(`${baseUrl}/getPrivateLessonsDetails.php`, {
                params: {
                    stdId: props.studentId,  
                    courseId: props.courseId,
                }
            });
    
            if (response.data.status === 'error') {
                throw new Error(response.data.message || 'Unknown error occurred');
            }
    
            setLines(Array.isArray(response.data) && response.data.length ? response.data : []);
    
        } catch (error) {
            alert('Error fetching private lessons details:', error.message);
        } finally {
            setFire(false); 
        }
    };  
//------------------------------------------------------------------------------------------- 

    const openBox = () => { 
        setShow(true);
        fetchPrivateLessonsDetails();  
    }
//------------------------------------------------------------------------------------------- 

    const closeBox = () => { 

          setShow(false);
    }
//------------------------------------------------------------------------------------------- 
    
    return (  
        <div>        
            
            <button 
                type="button" 
                className="btn btn-warning btn-sm btn-custom"  
                onClick={openBox}
            >
                מפגשים
            </button>
                
            <Modal 
              show={show} 
              onHide={closeBox} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header closeButton>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h4>מפגשים</h4></div>
                    </div>                                              
                </Modal.Header>  
                
                
                <Modal.Body> 
                    <div className="position-relative">

                        {fire?
                        <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                            <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                        </div>
                        :
                        null
                        }               
                                                    
                        <div class="mt-1">                                        
                        <Table hover >
                            <thead className=" text-center">
                            <tr>
                                <th>#</th>
                                <th>תאריך</th>
                                <th>נושא</th>
                            </tr>
                            </thead>
                            <tbody className=" text-center">
                            {
                                lines.map((row,index)=>(
                                <tr>                          
                                    <td>{index+1}</td>
                                    <td>{row.date}</td>                       
                                    <td>{row.subject}</td>                                                                            
                                </tr>                          
                                ))
                            } 
                            </tbody>                                                                                                                                 
                        </Table>
                        </div>

                    </div>
                                                                    
                </Modal.Body>  

                <Modal.Footer>
                    <div className="d-flex gap-3">                         
                        <Button variant="contained" color="error"   size="small" onClick={closeBox}>סגור</Button>                           
                    </div>
                </Modal.Footer> 
        
            </Modal> 
        </div>        
    )  
  } 
  
export default PrivateMeetingsList;  
