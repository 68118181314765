import { useState } from "react";
import {useNavigate } from 'react-router-dom';
import SelectUser from "../Components/select_user";
import SignIn from "../Components/signin";
import Social from "../Components/social";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip'; 
import SignUp from "../Components/signup";
import ForgotPassword from "../Components/forgot_password";
import TermsAndConditionsModal from "../Components/terms";
import Checkbox from '@mui/material/Checkbox';
import ReactLoading from 'react-loading';
import './login.css';

const TchLogin = (props) => {
  const [slide,setSlide]=useState(0);
  const [checked,setChecked]=useState(false);
  const [user,setUser]=useState('');
  const [fire,setFire]=useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const navigate = useNavigate();
      
  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);
  
  const connect=()=>{
    navigate("/");
    props.connect();
  }
//-------------------------------------------------------------------------------------------
  
  const isChecked=(checked)=>{
    setChecked(checked);
  }
//-------------------------------------------------------------------------------------------

  const changeUser=(user)=>{
    setUser(user);
  }
//-------------------------------------------------------------------------------------------
  return(
    <>
      <div className="d-flex flex-column align-items-center mt-4 mb-4" style={{minHeight:"350px"}}>

        <div className="login-width">
          {slide===0?
            <span className="titleStyle">כניסה לחשבון</span>
          :slide===1?
            <span className="titleStyle">הרשמה</span>
          :
            <span className="titleStyle">שחזור סיסמה</span> 
          }
        </div>             
        
        <div className="d-flex flex-column shadow gap-3 p-4 position-relative login-width">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }          
                                            
          <>
            <div>
              <SelectUser 
                isChecked={isChecked}
                changeUser={changeUser}
              />
            </div>

            <div>
              <Divider/>  
            </div>
          </>
          
          {slide!==2?  
            <>
              <div>
                <Social 
                  user={user} 
                  connect={connect} 
                  disabled={!checked || !termsAccepted}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                  mode={slide===0?'login':'signup'}
                />
              </div>

              <div>
                <Divider> 
                  <Chip 
                    label='או'  
                    size="small" 
                  />
                </Divider>
              </div>

            </>
            :
            null
          }   
                    
          <div>
            { slide===0?
                <SignIn 
                  user={user} 
                  connect={connect} 
                  disabled={!checked}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff} 
                />
              :slide===1?
                <>
                  <SignUp 
                    user={user} 
                    connect={connect}
                    disabled={!checked || !termsAccepted}
                    turnFireOn={turnFireOn}
                    turnFireOff={turnFireOff}
                  />
                  <div className="d-flex align-items-center mt-2 gap-2">
                    <Checkbox 
                      checked={termsAccepted} 
                      color="secondary"                   
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />                
                    <TermsAndConditionsModal/>
                  </div>
                </>
              :
                <ForgotPassword 
                  user={user}  
                  disabled={!checked}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                />
            } 
          </div> 
          
          { slide===0?
              <div className="d-flex justify-content-between">

                <div>
                  <a 
                    href="#" 
                    onClick={()=>{
                      setSlide(1)
                      setTermsAccepted(false)
                    }}
                  >
                    הרשמה
                  </a>
                </div>

                <div>
                  <a href="#" onClick={()=>{setSlide(2)}}>שכחתי סיסמה</a>
                </div>

              </div>
            :
              <div >
                <a 
                  className='float-end' 
                  href="#" 
                  onClick={()=>{
                    setSlide(0)
                    setTermsAccepted(true)
                  }}
                >
                  כניסה לחשבון
                </a>
              </div>
          }
                
        </div> 

        <div className='mt-3'>
          <span>www.doroosonline.co.il</span>
        </div>

      </div>
    </>
                                
  )};

export default TchLogin;
