import { Navbar, Nav,NavDropdown} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import MessageBadge from '../Components/message_badge';
import DocsBadge from '../Components/docs_badge';
import TchrsBadge from '../Components/tchrs_badge';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import Help from '../Components/help';
import '../Layouts/header.css'

const SchHeader = (props) => {
  return (
    <>
      <Navbar className='position-relative' collapseOnSelect expand='md' bg='dark' variant='dark'>
          
          <Navbar.Toggle className='ms-4' aria-controls='responsive-navbar-nav'/>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-md-2 mb-4 mb-md-2 ms-4 ms-md-5 align-items-start align-items-md-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>
                <Link className='nav-item' to="/courses">קורסים שלי</Link>
                <Link className='nav-item' to="/schreport">דוח שעות</Link>
                <Link className='nav-item me-2' to="/ConfirmDocs" ><DocsBadge  count={props.docsNum}/></Link>
                <Link className='nav-item me-2' to="/ConfirmTchrs"><TchrsBadge count={props.tchsNum}/></Link>            
                <Link className='nav-item' to="/schprofile">פרופיל</Link>
                <Link className='nav-item' to="/contact">צור קשר</Link>
                <NavDropdown 
                  title="עזרה" 
                  id="navbarScrollingDropdown" 
                  
                >
                  <NavDropdown.Item 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }} 
                  >
                    <i className="fas fa-chalkboard-teacher"></i> 
                    <Help btn_txt="מדריך מורה" help="teacher_guide.pdf" />
                  </NavDropdown.Item>

                  <NavDropdown.Divider /> 

                  <NavDropdown.Item 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <i className="fas fa-user-graduate"></i> 
                    <Help btn_txt="מדריך תלמיד" help="student_guide.pdf" />
                  </NavDropdown.Item>
                </NavDropdown> 
                {/*<Link className='nav-item' to="/messages"><MessageBadge/></Link>*/}                           
            </Nav>
          </Navbar.Collapse>
          <a className='nav-item position-absolute login-pos' onClick={() => props.disconnect()}><LogoutIcon/><b> יציאה</b></a>
        
      </Navbar>

      <Outlet />

    </>
  )
};

export default SchHeader;