import {useNavigate,useLocation} from 'react-router-dom';
import SignIn from "../Components/signin";
import Social from "../Components/social";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip'; 
import { useState,useEffect} from 'react';
import SignUp from '../Components/signup';
import ForgotPassword from '../Components/forgot_password';
import TermsAndConditionsModal from "../Components/terms";
import ReactLoading from 'react-loading';
import Checkbox from '@mui/material/Checkbox';
import './login.css';

const Login = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);
  const [slide,setSlide]=useState(0);
  const [fire,setFire]=useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);
      
  const connect=()=>{
    window.sessionStorage.setItem("user","STD");

    if (location.state) {
      navigate('/search', { state: { code: location.state.code, mode: location.state.mode } });
    }
    else{
      navigate("/");
    }
      
    props.connect();
  }
  
  return(
    <>

      <div className="d-flex flex-column align-items-center mt-5 mb-5" style={{ minHeight: `${screenHeight}px`}}>

        <div className="login-width">
          { slide===0?
              <span className="titleStyle" >כניסה לחשבון</span>
            :slide===1?
              <span className="titleStyle" >הרשמה</span>
            :
              <span className="titleStyle" >שחזור סיסמה</span>
          }
        </div>             
        
        <div className="d-flex flex-column shadow gap-3 p-4 position-relative login-width">          

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }    

          { slide!==2?
              <>
                <div>
                  <Social 
                    user="STD" 
                    connect={connect} 
                    disabled={!termsAccepted}
                    turnFireOn={turnFireOn}
                    turnFireOff={turnFireOff}
                    mode={slide===0?'login':'signup'}
                  />
                </div>  

                <Divider>
                  <Chip 
                    label='או'  
                    size="small" 
                  />
                </Divider>
              </>   
            :null
          }
        
          <div>
            { slide===0?
                <SignIn 
                  user="STD" 
                  connect={connect}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                />
              :slide===1?
                <>
                  <SignUp 
                    user="STD"
                    connect={connect}
                    disabled={!termsAccepted}
                    turnFireOn={turnFireOn}
                    turnFireOff={turnFireOff}
                  />
                  <div className="d-flex align-items-center mt-2 gap-2">
                    <Checkbox 
                      checked={termsAccepted} 
                      color="secondary"                   
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />                
                    <TermsAndConditionsModal/>
                  </div>
                </>
              :
                <ForgotPassword
                  user="STD"
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                />
            } 
          </div> 
          
          {slide===0?
            <div className="d-flex justify-content-between">

              <div>
                <a 
                  href="#" 
                  onClick={()=>{
                    setSlide(1)
                    setTermsAccepted(false)
                  }}
                >
                  הרשמה
                </a>
              </div>

              <div>
                <a href="#" onClick={()=>{setSlide(2)}}>שכחתי סיסמה</a>
              </div>

            </div>
            :
            <div >              
              <a 
                  className='float-end' 
                  href="#" 
                  onClick={()=>{
                    setSlide(0)
                    setTermsAccepted(true)
                  }}
                >
                  כניסה לחשבון
              </a>
            </div>
          }
                
        </div> 

        <div className='mt-3'>
          <span>www.doroosonline.co.il</span>
        </div>

      </div>
    </>
                                
  )};
  
export default Login;
