import  React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import MeetingReqBox from "../Components/meeting_req_box";
import SearchByDates from "../Components/search_by_date";
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { addMinutes } from "../Components/time";
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';

const baseUrl = process.env.REACT_APP_BASE_URL;  

const MeetingRequests = (props) => {

  const [requests,setRequests]=useState([]);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
  
  useEffect(()=>{
    
    const fetchMeetingsRequests = async () => {
      try {

        setFire(true);
    
        const response = await axios.get(`${baseUrl}/meetings_requests.php`, {
          params: {                        
            tchId: props.userId                                                                   
          }
        });
    
        if (response.data && response.data.status === 'error') {
          alert(response.data.message);
        } else if (Array.isArray(response.data) && response.data.length) {
          setRequests(response.data);
        } else {
          setRequests([]);
        }

      } catch (error) {
        alert(error.message || "An error occurred while fetching data.");
      } finally {
        setFire(false);
      }
    }
    
    fetchMeetingsRequests();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const set_show_overlay= (value)=>{
    setShowOverlay(value);
  }
//-------------------------------------------------------------------------------------------

  const fetchMeetingsRequestsByDates = async (sDate, eDate) => {
    try {
      setFire(true);
      const response = await axios.get(`${baseUrl}/meetings_requests_by_dates.php`, {
        params: {                        
          tchId: props.userId,
          sDate: sDate,
          eDate: eDate                                                                 
        }
      });

      if (response.data && response.data.status === 'error') {
        alert(response.data.message);
        setRequests([]); 
      } else if (Array.isArray(response.data) && response.data.length) {
        setRequests(response.data);
      } else {
        setRequests([]);
      }
    } 
    catch (error) {
      alert(error.message || "An error occurred while fetching data.");
    } finally {
      setFire(false);
    }
  }
//-------------------------------------------------------------------------------------------
  
  return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>

        {showOverlay && 
          <Box 
            position="fixed" 
            top={0} 
            left={0} 
            width="100%" 
            height="100%" 
            bgcolor="rgba(0,0,0,0.5)" 
            zIndex={9999}
          ></Box>
      
        }
                      
        <div className="mt-4">
          <PageTitle text="אישור מפגשים פרטיים"/>
        </div>

        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchMeetingsRequestsByDates} minDate={0}/>
        </div>

        <div className="mt-4">          
          <Divider></Divider>
        </div>
                
        <div  className="position-relative mt-4">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          <div className="d-flex flex-column gap-2"> 
            {requests.map((req,index)=>(                                                                                                                      
                <MeetingReqBox 
                  index={index} 
                  bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                   
                  photo={req.photo}
                  tchId={props.userId}
                  subject={req.subject} 
                  date={req.date}
                  start={req.start} 
                  courseId={req.courseId}
                  studentId={req.studentId}
                  end={addMinutes(req.start,req.num*50)}                                
                  lessonId={req.lessonId}
                  num={req.num}              
                  price={req.price*req.num} 
                  status={req.status}
                  decreaseRequestsNum={props.decreaseRequestsNum}  
                  set_show_overlay={set_show_overlay} 
                  turnFireOn={turnFireOn} 
                  turnFireOff={turnFireOff}                                                                                                                       
                />            
            ))}
          </div>
        </div>               
    </div>
  );
};

export default MeetingRequests;


